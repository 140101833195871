
import { DataService } from '../../data.service';
import { HelperService } from '../../helper.service';

export class Profile {

  constructor(private dataService: DataService, private helperService: HelperService) {}

  retrieved = async (data: any) => {
    try {
  
      var { profiles } = data;

      for (var i = 0; i < profiles.length; i++) {

        profiles[i].createdAt = new Date(profiles[i].createdAt).toLocaleString();

        const accounts =  profiles[i].accounts;
        const type = profiles[i].type;
        const display = this.helperService.processTypeDisplay(accounts, type);
        profiles[i].display = display;
      }
  
      this.dataService.setProperty('profiles', profiles);

      this.helperService.hideLoading();
  
      return;
  
    } catch (error) {
      console.log(error);
      return;
    }
  }
  
  deleted = async (data: any) => {
    try {
  
      //console.log('admin_profile_deleted', data);
  
      const { profile } = data;
      const profileId = profile._id;
  
      const profiles = this.dataService.getProperty('profiles');
  
      for (var i = 0; i < profiles.length; i++) {
        if (profiles[i]._id === profileId) {
          profiles.splice(i, 1);
          break;
        }
      }
  
      this.dataService.setProperty('profiles', profiles);

      const selectedProfile = this.dataService.getProperty('selectedProfile');

      if (selectedProfile && selectedProfile._id === profileId) {
        this.dataService.deleteProperty('selectedProfile');
      }
  
      return;
  
    } catch (error) {
      console.log(error);
      return;
    }
  }
  
  updated = async (data: any) => {
    try {
  
      //console.log('admin_profile_updated', data);
  
      var { profile } = data;
      const profileId = profile._id;

      const accounts =  profile.accounts;
      const type = profile.type;
      const display = this.helperService.processTypeDisplay(accounts, type);
      profile.display = display;
  
      var profiles = this.dataService.getProperty('profiles');
  
      for (var i = 0; i < profiles.length; i++) {
        if (profiles[i]._id === profileId) {
          profiles[i] = profile;
          break;
        }
      }
  
      this.dataService.setProperty('profiles', profiles);

      var selectedProfile = this.dataService.getProperty('selectedProfile');

      if (selectedProfile && selectedProfile._id === profileId) {
        console.log('selectedProfile', profile);
        this.dataService.setProperty('selectedProfile', profile);
      }
  
      return;
  
    } catch (error) {
      console.log(error);
      return;
    }
  }
  
  created = async (data: any) => {
    try {
  
      //console.log('admin_profile_created', data);
  
      var { profile } = data;

      const accounts =  profile.accounts;
      const type = profile.type;
      const display = this.helperService.processTypeDisplay(accounts, type);
      profile.display = display;
  
      const profiles = this.dataService.getProperty('profiles');

      profiles.push(profile);

      this.dataService.setProperty('profiles', profiles);
  
      return;
  
    } catch (error) {
      console.log(error);
      return;
    }
  }

  enabled = async (data: any) => {
    try {
  
      //console.log('admin_profile_enabled', data);
  
      const { profileId } = data;
  
      var profiles = this.dataService.getProperty('profiles');
  
      for (var i = 0; i < profiles.length; i++) {
        if (profiles[i]._id === profileId) {
          profiles[i].active = true;
          break;
        }
      }
  
      this.dataService.setProperty('profiles', profiles);

      var selectedProfile = this.dataService.getProperty('selectedProfile');

      if (selectedProfile && selectedProfile._id === profileId) {
        selectedProfile.active = true;
        this.dataService.setProperty('selectedProfile', selectedProfile);
      }
  
      return;
  
    } catch (error) {
      console.log(error);
      return;
    }
  }

  disabled = async (data: any) => {
    try {
  
      //console.log('admin_profile_disabled', data);
  
      const { profileId } = data;
  
      var profiles = this.dataService.getProperty('profiles');
  
      for (var i = 0; i < profiles.length; i++) {
        if (profiles[i]._id === profileId) {
          profiles[i].active = false;
          break;
        }
      }
  
      this.dataService.setProperty('profiles', profiles);

      var selectedProfile = this.dataService.getProperty('selectedProfile');

      if (selectedProfile && selectedProfile._id === profileId) {
        selectedProfile.active = false;
        this.dataService.setProperty('selectedProfile', selectedProfile);
      }
  
      return;
  
    } catch (error) {
      console.log(error);
      return;
    }
  }

}