
export class Ticket {

    constructor(private dataService: any, private helperService: any) {}

    retrieved = async (data: any) => {
      try {
    
        //console.log('admin_account_tickets_retrieved', data);
    
        var { tickets } = data;

        var open = tickets.open;
        var closed = tickets.closed;

        for (var i = 0; i < open.length; i++) {

          open[i].createdAt = new Date(open[i].createdAt).toLocaleString();
          open[i].updatedAt = new Date(open[i].updatedAt).toLocaleString();

          for (var j = 0; j < open[i].notes.length; j++) {
            open[i].notes[j].createdAt = new Date(open[i].notes[j].createdAt).toLocaleString();
          }
        }

        for (var i = 0; i < closed.length; i++) {

          closed[i].createdAt = new Date(closed[i].createdAt).toLocaleString();
          closed[i].updatedAt = new Date(closed[i].updatedAt).toLocaleString();
          
          for (var j = 0; j < closed[i].notes.length; j++) {
            closed[i].notes[j].createdAt = new Date(closed[i].notes[j].createdAt).toLocaleString();
          }
        }

        tickets.open = open.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        tickets.closed = closed.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    
        this.dataService.setProperty('tickets', tickets);
    
        return;
    
      } catch (error) {
        console.log(error);
        return;
      }
    }
    
    created = async (data: any) => {
      try {
  
        //console.log('admin_account_ticket_created', data);
  
        const { ticket } = data;
  
        var tickets = this.dataService.getProperty('tickets');

        tickets.open.push(ticket);
        tickets.open = tickets.open.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

        this.dataService.setProperty('tickets', tickets);
  
        return;
  
      } catch (error) {
        console.log(error);
        return;
      }
    }
  
    updated = async (data: any) => {
      try {
  
        //console.log('admin_account_ticket_updated', data);
  
        const { ticket } = data;
  
        var tickets = this.dataService.getProperty('tickets');
  
        for (var i = 0; i < tickets.open.length; i++) {
          if (tickets.open[i]._id === ticket._id) {
            tickets.open[i] = ticket;
            break;
          }
        }

        tickets.open = tickets.open.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

        for (var i = 0; i < tickets.closed.length; i++) {
          if (tickets.closed[i]._id === ticket._id) {
            tickets.closed[i] = ticket;
            break;
          }
        }

        tickets.closed = tickets.closed.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  
        this.dataService.setProperty('tickets', tickets);

        if (this.dataService.getProperty('selectedTicket') && this.dataService.getProperty('selectedTicket')._id === ticket._id) {
          this.dataService.setProperty('selectedTicket', ticket);
        }
  
        return;
  
      } catch (error) {
        console.log(error);
        return;
      }
    }
  
    closed = async (data: any) => {
      try {
  
        //console.log('admin_account_ticket_closed', data);
  
        const { ticket } = data;
  
        var tickets = this.dataService.getProperty('tickets');
  
        for (var i = 0; i < tickets.open.length; i++) {
          if (tickets.open[i]._id === ticket._id) {
            tickets.open.splice(i, 1);
            break;
          }
        }
  
        tickets.closed.push(ticket);

        tickets.closed = tickets.closed.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  
        this.dataService.setProperty('tickets', tickets);

        if (this.dataService.getProperty('selectedTicket') && this.dataService.getProperty('selectedTicket')._id === ticket._id) {
          this.dataService.setProperty('selectedTicket', ticket);
        }
  
        return;
  
      } catch (error) {
        console.log(error);
        return;
      }
    }
  
    reopened = async (data: any) => {
      try {
  
        //console.log('admin_account_ticket_reopened', data);
  
        const { ticket } = data;
  
        var tickets = this.dataService.getProperty('tickets');
  
        for (var i = 0; i < tickets.closed.length; i++) {
          if (tickets.closed[i]._id === ticket._id) {
            tickets.closed.splice(i, 1);
            break;
          }
        }
  
        tickets.open.push(ticket);

        tickets.open = tickets.open.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  
        this.dataService.setProperty('tickets', tickets);

        if (this.dataService.getProperty('selectedTicket') && this.dataService.getProperty('selectedTicket')._id === ticket._id) {
          this.dataService.setProperty('selectedTicket', ticket);
        }
  
        return;
  
      } catch (error) {
        console.log(error);
        return;
      }
    }
  
    deleted = async (data: any) => {
      try {
  
        //console.log('admin_account_ticket_deleted', data);
  
        const { ticketId } = data;
  
        var tickets = this.dataService.getProperty('tickets');
  
        for (var i = 0; i < tickets.open.length; i++) {
          if (tickets.open[i]._id === ticketId) {
            tickets.open.splice(i, 1);
            break;
          }
        }

        tickets.open = tickets.open.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  
        for (var i = 0; i < tickets.closed.length; i++) {
          if (tickets.closed[i]._id === ticketId) {
            tickets.closed.splice(i, 1);
            break;
          }
        }

        tickets.closed = tickets.closed.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  
        this.dataService.setProperty('tickets', tickets);

        if (this.dataService.getProperty('selectedTicket') && this.dataService.getProperty('selectedTicket')._id === ticketId) {
          this.dataService.deleteProperty('selectedTicket');
        }
  
        return;
  
      } catch (error) {
        console.log(error);
        return;
      }
    }
}